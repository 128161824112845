<template>
    <div class="page-background">
        <page-header />
        <div  class="container main-area">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="content_wrap main_content_bg">
                        <div  class="content clearfix font_conf">
                            <!-- <?php if($order_number): ?> -->
                            <!-- <?php if($order_sum['ord_type'] == 'cerere oferta'): ?> -->
                            <!-- <h3 class="final-order-text">Felicitari! Cererea dumneavoastra a fost trimisa cu success!</h3> -->
                            <!-- <p>Un reprezentant al firmei te va contacta in cel mai scurt timp.</p> -->
                            <!-- <p>Pentru mai multe detalii, apeleaza-ne la 0737.364.633</p> -->
                            <!-- <p>Iti multumim pentru increderea acordata si te asiguram ca ai facut cea mai buna alegere!</p> -->
                            <!-- <?php else: ?> -->
                            <div class="final-order-text">
                                Felicitări, comanda ta a fost salvată cu succes.
                            </div>
                            <div class="list-wrapper">
                                <img 
                                    v-if="windowWidth > 768"
                                    class="final-order-img"
                                    src="@/assets/images/list-order-confirmation.png"
                                    alt="order confirmation points"
                                    loading="lazy"
                                />
                                <img 
                                    v-if="windowWidth <= 768"
                                    class="final-order-img"
                                    src="@/assets/images/list-order-confirmation-mobile.png"
                                    alt="order confirmation points"
                                    loading="lazy"
                                />
                                <ul class="order-confirmation-list">
                                    <li>Numărul comenzii tale este: <span style="font-family: 'Montserrat Bold';">{{ order.data.number }}</span></li>
                                    <li>Un email de confirmare a fost trimis la adresa: <span style="font-family: 'Montserrat Bold';">{{ order.formData.user.email }}</span></li>
                                    <li>Un reprezentant al echipei noastre te va contacta iar apoi comanda urmează să fie livrată în cel mai scurt timp.</li>
                                </ul>
                            </div>
                            <div class="row no-gutters">
                                <div class="col-sm-12 order-2 col-md-6 order-md-1">
                                    <h1 style="font-family: Raleway Bold;">Îți mulțumim pentru încrederea acordată şi te asigurăm că ai făcut cea mai bună alegere.</h1>
                                    <div>Pentru mai multe detalii, ne poți apela la <span style="font-family: 'Montserrat Bold';">0377.101.500</span></div>
                                </div>
                                <div class="col-sm-12 order-1 col-md-6 order-md-2 mt-4 mt-desktop">
                                    <img 
                                        style="width: 100%;"
                                        src="@/assets/images/logo-order-confirmation.png"
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <iframe :src="profitshareUrl()" alt="" border="" width="1" height="1" style="border:none !important; margin:0px !important;"></iframe>
        <iframe :src="performantUrl()" height='1' width='1' scrolling='no' marginheight='0' marginwidth='0' frameborder='0'></iframe>
    </div>
</template>

<script>
import PageHeader from "@/components/page_header/PageHeader.vue";
import CryptoJS from 'crypto-js'

export default {
    components: {
        PageHeader,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            commissionCategories: [],
        };
    },
    computed: {
        order() {
            return this.$store.getters["shop/order"];
        },
        finalOrderCart() {
            return this.$store.getters["shop/finalOrderCart"];
        },
    },
    created() {
        if (
            this.order &&
            Object.keys(this.order).length === 0 &&
            this.order.constructor === Object
        ) {
            this.$router.push({ name: "Cart" });
        }


    },
    mounted() {
        var google_items = []
        var performantItems = [];
        this.finalOrderCart.positions.forEach((value) => {
            var prod_categ_name = value.product.category_name;

            google_items.push({
                "item_id": value.product.code,
                "item_name": value.product.name,
                "item_brand": value.product.brand_name,
                "item_category": prod_categ_name,
                "quantity": value.quantity,
                "price": value.price_per_unit_value
            });
    
            performantItems.push({
                quantity: value.quantity,
                product_id: value.product.id.toString(),
                value: Number(value.price_per_unit_without_tax_value.toFixed(2)),
                name: value.product.name,
                category: [prod_categ_name],
                brand: value.product.brand_name,
                commission_percent: Number(value.product.commission_2pf),
            });
        });
        
        this.$gtm.trackEvent({
            event: "purchase",
            ecommerce: {
                value: this.finalOrderCart.total_value,
                transaction_id: this.order.data.number,
                currency: "RON",
                shipping: this.finalOrderCart.shipping_price.old_price_value,
                items: google_items
            },
        });

        var performantTpOrder = {
            id: this.order.data.number,
            placed_at: Math.floor(new Date().getTime() / 1000),
            currency_code: "RON",
            items: performantItems,
        };
        
        let performantScriptItems = document.createElement('script');
        let inlineCode = document.createTextNode('var tpOrder = ' + JSON.stringify(performantTpOrder).replace(/"([^"]+)":/g, '$1:'));
        performantScriptItems.appendChild(inlineCode); 
        document.head.appendChild(performantScriptItems);

        let performantScript = document.createElement('script');
        performantScript.setAttribute('src', 'https://attr-2p.com/c4f881ff0/sls/1.js');
        performantScript.setAttribute('defer', '');
        document.head.appendChild(performantScript);
    },
    methods: {
        profitshareEncrypt(plaintext, key) {
            let iv = CryptoJS.enc.Utf8.parse(Math.round((Math.pow(36, 16 + 1) - Math.random() * Math.pow(36, 16))).toString(36).slice(1));

            let subKey = CryptoJS.enc.Utf8.parse(key.substring(0, 16));
            key = CryptoJS.enc.Utf8.parse(key);

            let chiperData = CryptoJS.AES.encrypt(plaintext, subKey, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
            });

            let hash = CryptoJS.HmacSHA256(chiperData.ciphertext, key);
            let hmac = atob(hash.toString(CryptoJS.enc.Base64));

            iv = atob(iv.toString(CryptoJS.enc.Base64)); 
            let chiperRaw = atob(chiperData.toString());

            return this.profitshareBin2hex(btoa(iv + hmac + chiperRaw)); 
        },

        profitshareBin2hex(s){ 
            var i, f = 0, a = []; 
            s += ''; 
            f = s.length; 

            for (i = 0; i<f; i++) { 
                a[i] = s.charCodeAt(i).toString(16).replace(/^([\da-f])$/,"0$1"); 
            } 

            return a.join(''); 
        },

        profitshareUrl() {
            var affiliate_items = [];
            var products_campaign_777 = [2205, 2206, 2207, 2225, 3801, 3807, 6910, 6911, 6913, 6915, 6916, 6918, 6920, 6921, 6977, 6978, 21075, 21076, 21093, 21095, 21097, 21099, 21101, 21103, 21105, 21107];
            var products_campaign_888 = [21266, 21267, 21273, 21277, 21278, 21280, 21284, 21285, 21286, 21291, 21292, 21302, 21303, 21309, 21324, 21330, 21333, 21355, 21356, 21358, 21371, 21393, 21468, 21473, 21475, 21477, 21479, 21483, 21693, 21699];

            this.finalOrderCart.positions.forEach((value) => {
                
                var slug = parseInt(value.product.slug.split('_').pop());
                var prod_categ_id = value.product.category_id;
                var prod_categ_name = value.product.category_name;

                if(products_campaign_777.includes(slug)) {
                    prod_categ_id = 777;
                    prod_categ_name = "Campanie10%E";
                }
                if(products_campaign_888.includes(slug)) {
                    prod_categ_id = 888;
                    prod_categ_name = "Campanie10%G";
                }
                
                affiliate_items.push({
                    "product_code": value.product.id,
                    "product_part_no": value.product.code,
                    "product_price": value.price_per_unit_without_tax,
                    "product_name": value.product.name,
                    "product_link": window.location.origin + "/produs/" + value.product.slug,
                    "product_category": prod_categ_id,
                    "product_category_name": prod_categ_name,
                    "product_brand_code": value.product.brand_id,
                    "product_brand": value.product.brand_name,
                    "product_qty": value.quantity,
                });

            });
            var queryString = new URLSearchParams("");
            queryString.append("external_reference", this.order.data.number);
            for (var i = 0; i < affiliate_items.length; i++) {
                for (var k in affiliate_items[i]) {
                    queryString.append(k + "[" + i + "]", affiliate_items[i][k]);
                }
            }
            const name = "click_code";
            const click_code = (document.cookie.match('(?:^|;)\\s*'+name.trim()+'\\s*=\\s*([^;]*?)\\s*(?:;|$)')||[])[1];
            queryString = queryString.toString();
            return "//c.profitshare.ro/ca/0/db1162c56479b9ba09d8496c36d990b3ceb7508388cd4180faef62cdb75efbad6459bf158d2ec91b/p/"+ this.profitshareEncrypt(queryString, '4203b850db53c859e08201b7da04f39c') +"?click_code=" + click_code;
        },

        performantUrl() {
            var transactionId = this.order.data.number;
            // var saleValue = this.finalOrderCart.position_total_price.price_without_tax_value.toFixed(2);
            var saleValue = [];
            var description = '';
            var comPercent = [];
            this.finalOrderCart.positions.forEach((value) => {
                description += value.quantity + 'x ' + value.product.name + '; ';
                if(value.quantity > 1) {
                    for(let i=0; i<value.quantity; i++) {
                        comPercent.push(value.product.commission_2pf);
                        saleValue.push(value.price_per_unit_without_tax_value);
                    }
                } else {
                    comPercent.push(value.product.commission_2pf);
                    saleValue.push(value.price_per_unit_without_tax_value);
                }   
            });

            description = encodeURIComponent(description);
            comPercent = encodeURIComponent('[' + comPercent + ']');
            saleValue = encodeURIComponent('[' + saleValue + ']');

            var url = 'https://event.2performant.com/events/salecheck?campaign_unique=a71311b5a&confirm=e83d215489942742&transaction_id='+transactionId+'&description='+description+'&amount='+saleValue+'&com_percent='+comPercent;
            return url;
        },
    }
};
</script>

<style scoped>
.content_wrap {
    margin-top: 15px;
}

.final-order-text {
    font-family: Raleway Bold;
    /* color: #008c00; */
    font-size: 50px;
    margin-top: 50px;
}

.font_conf h4 {
    font-weight: 700;
    margin-bottom: 25px;
    margin-top: 25px;
}

.font_conf p {
    font-size: 19px;
}

.container.main-area.default-page {
    margin-bottom: 250px;
    min-height: 700px;
    padding-bottom: 0;
}

.page-background {
    background-color: #f4f4f4; 
    padding-bottom: 100px
}

.list-wrapper {
    position: relative;
    margin-top: 32px;
}

.final-order-img {
    height: 156px;
    position: absolute;
    top: 0;
}

.order-confirmation-list {
    display: inline-block;
    list-style: none;
    margin-left: 18px;
    padding-top: 5px;
}

.order-confirmation-list li{
    padding-bottom: 38px;
    font-family: Montserrat Regular;
}

@media screen and (min-width: 768px) {
    .mt-desktop {
        margin-top: 85px !important;
    }
}

@media screen and (max-width: 768px) {
    .final-order-img {
        height: 194px;
    }

    .final-order-text {
        font-size: 40px;
        line-height: 40px;
    }

    .order-confirmation-list {
        margin-left: 10px;
    }

    .order-confirmation-list li{
        padding-bottom: 40px;
    }
}

@media screen and (max-width: 390px) {
    .order-confirmation-list li{
        padding-bottom: 18px;
    }
}

</style>
