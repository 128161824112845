<template>
  <div>
    <page-header :item="header_image" :overlay="false" />
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-8 col-12">
          <checkout-form />
          <!-- <TestSchema /> -->
        </div>
        <div class="col-lg-3 col-md-4 col-12 d-none d-md-block">
          <summar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/page_header/PageHeader.vue";
import CheckoutForm from "@/components/checkout/CheckoutForm.vue";
// import TestSchema from "@/components/checkout/TestSchema.vue";
import Summar from "@/components/cart/Summar.vue";

export default {
  name: "Checkout",
  components: {
    "page-header": PageHeader,
    "checkout-form": CheckoutForm,
    // TestSchema,
    Summar,
  },
  computed: {
    header_image() {
      return {
        path: this.$store.getters["seo/seo_image"],
      };
    },
  },
  mounted() {
    this.$store.commit("seo/setParams", {
      seo_image: require("@/assets/images/checkout-slider.webp"),
    });
  },
};
</script>

<style>
@import "../../assets/css/checkout.css";
</style>
